import React, { Component } from "react";
import { Card, CardActionArea, Typography, Grid } from "@material-ui/core";
import  ArrowFoward  from '@material-ui/icons/ArrowForwardIosOutlined'

export default class NewDemandSplash extends Component {
  render() {
    return (
      <Card style={{height: '100%'}}>
        <CardActionArea onClick={() => this.props.action()}>
          <Grid container spacing={8} >
            <Grid item container justify='center' direction='column' xs={12} sm={8} style={{ padding: 40 }}>
              <Typography
                variant='h5'
                color='primary'
                style={{ lineHeight: "1.2" }}
              >
                {this.props.title}
              </Typography>
              <Typography variant='body1' style={{marginTop: 20}}>{this.props.description}</Typography>
            </Grid>
            <Grid item container justify='center' alignItems='center' xs={12} sm={4} style={{backgroundColor: this.props.color, padding: 20}}>
              <ArrowFoward className='white-info' style={{fontSize: '5vh'}}/>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    );
  }
}
