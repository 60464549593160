import api from './api'

class ExternalLogin {
	async login(cim, senha) {
		return api.post("/Brother/login", {
			usuario: cim,
			senha: senha,
		})
	}
}

export default new ExternalLogin();
