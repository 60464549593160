import React, { Component } from "react";
import {
  Paper,
  Grid,
  TextField,
  Typography,
  MenuItem,
  FormHelperText,
  Avatar
} from "@material-ui/core";
import BigButtonWithLoading from "../../../components/BigButtonWithLoading";

export default class NewDemandForm extends Component {
  render() {
    return (
      <Grid item xs={11} sm={10} md={8}>
        <Paper style={{ padding: 32 }}>
          <Grid container justify='space-around' spacing={16}>
            <Grid item xs={12} container justify='space-between'>
              <Grid item>
                <Typography variant='h5'>
                  {this.props.privacy === "public"
                    ? "Nova Demanda Pública"
                    : "Nova Demanda Privada"}
                </Typography>
                <Typography variant='body2' color='secondary'>
                  {this.props.privacy === "public"
                    ? "Para: Todos os irmãos"
                    : "Para: " + this.props.brother.pessoa_nome}
                </Typography>
              </Grid>
              <Grid item>
                {this.props.privacy !== "public" && (
                  <Avatar
                    src={
                      this.props.brother.foto
                        ? this.props.brother.foto
                        : ""
                    }
                    style={{
                      width: 55,
                      height: 55
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id='title'
                label='Título da Demanda'
                value={this.props.title}
                variant='filled'
                fullWidth
                onChange={e => this.props.handler("title", e.target.value)}
                InputLabelProps={{ shrink: true }}
                disabled={this.props.status !== ""}
                error={this.props.errorTitle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                id='classification'
                label='Classificação'
                value={this.props.classification}
                variant='filled'
                fullWidth
                onChange={e =>
                  this.props.handler("classification", e.target.value)
                }
                InputLabelProps={{ shrink: true }}
                disabled={this.props.status !== ""}
              >
                <MenuItem key='masonic' value='masonic'>
                  De ordem maçônica
                </MenuItem>
                <MenuItem key='professional' value='professional'>
                  De ordem profissional ou associada ao trabalho
                </MenuItem>
                <MenuItem key='services' value='services'>
                  Demanda por serviços ou produtos em geral
                </MenuItem>
                <MenuItem key='health' value='health'>
                  De ordem médica ou associada à saúde
                </MenuItem>
                <MenuItem key='juridic' value='juridic'>
                  De ordem jurídica ou associada à justiça
                </MenuItem>
                <MenuItem key='personal' value='personal'>
                  De ordem pessoal ou familiar
                </MenuItem>
                <MenuItem key='others' value='others'>
                  Outros
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='description'
                label='Descrição da Demanda'
                value={this.props.description}
                variant='filled'
                fullWidth
                onChange={e =>
                  this.props.handler("description", e.target.value)
                }
                InputLabelProps={{ shrink: true }}
                multiline
                rows='15'
                disabled={this.props.status !== ""}
                error={this.props.errorDescription}
              />
              <FormHelperText>
                Informe aqui os detalhes de sua demanda. Quanto mais
                informações, maior a chance de obter ajuda.
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <BigButtonWithLoading
                title='Enviar Demanda'
                loadingTitle='Enviando Demanda ...'
                successTitle='Demanda Enviada'
                icon='send'
                action={this.props.sendAction}
                status={this.props.status}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}
