import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "../services/auth";
import AppTopBar from "./components/AppTopBar";
import Login from "./routes/Login";
import Home from "./routes/Home";
import DemandList from "./routes/DemandList";
import DemandView from "./routes/DemandView";
import BrotherSearch from "./routes/BrotherSearch";
import BrotherView from "./routes/BrotherView";
import DemandCreation from "./routes/DemandCreation";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import AppTheme from "./theme/appTheme.js";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

const applyTheme = createMuiTheme(AppTheme);
const Routes = () => (
  <div className='App' style={{ backgroundColor: "rgba(56, 68, 89, 0.1)" }}>
    <MuiThemeProvider theme={applyTheme}>
      <BrowserRouter>
        <AppTopBar />
        <div style={{ minHeight: "calc(100vh - 73px)" }}>
          <Switch>
            <Route path={`/login`} component={Login} />
            <PrivateRoute exact path='/' component={Home} />
            <PrivateRoute path={`/home`} component={Home} />
            <PrivateRoute path={`/demandas`} component={DemandList} />
            <PrivateRoute path={`/demanda/:id`} component={DemandView} />
            <PrivateRoute path={`/buscaIrmao`} component={BrotherSearch} />
            <PrivateRoute path={`/irmao/:cim`} component={BrotherView} />
            <PrivateRoute
              path={`/novaDemanda/:cim`}
              component={DemandCreation}
            />
            <PrivateRoute path={`/novaDemanda`} component={DemandCreation} />
          </Switch>
        </div>
      </BrowserRouter>
    </MuiThemeProvider>
  </div>
);

export default Routes;
