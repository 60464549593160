import api from "./api";

class DemandService {

  async getAllDemands() {
    return await api.get("/demand/getAll");
  }

  async getAllPublicDemands() {
    return await api.get("/demand/getAllPublicDemands");
  }

  async getDemandByRequested(id) {
    return await api.get(`/demand/getDemandByRequested/${id}`);
  }

  async getDemandById(id) {
    return await api.get(`/demand/getDemandById/${id}`);
  }

  async getDemandByCreator(id) {
    return await api.get(`/demand/getDemandByCreator/${id}`);
  }

  async getDemandsCounter(id) {
    return await api.get(`/demand/count/${id}`);
  }

  async insertPublicDemand(demand) {
    return await api.post("/demand/insertPublicDemand", {
      title: demand.title,
      description: demand.description,
      creator_id: demand.creator_id,
      creator_name: demand.creator_name,
      type: 1,
      classification: demand.classification
    });
  }

  async insertDemandUser(demand, user) {
    return await api.post("/demand/insertDemandUser", {
      demand: {
        id: demand.demand_id,
        creator_id: demand.creator_id
      },
      user: {
        id: user.id,
        requested_name: user.name
      }
    });
  }

  async insertPrivateDemand(demand, user) {
    return await api.post("/demand/insertPrivateDemand", {
      demand: {
        title: demand.title,
        description: demand.description,
        creator_id: demand.creator_id,
        type: 2,
        classification: demand.classification,
        creator_name: demand.creator_name
      },
      user
    });
  }

  async fileDemand(filed) {
    //Filed_by has 3 options
    // 1	GOSC
    // 2	DEMANDANTE
    // 3	DEMANDADO


    return await api.put("/demand/fileDemand", {
      id: filed.id,
      text: filed.text,
      filed_by: filed.filed_by,
      type: filed.type
    });
  }

  async changeStatusDemand(demand) {
    return await api.put("/Demand/updateDemandStatus", {
      id: demand.id,
      status: demand.status
    });
  }


}

export default new DemandService();
