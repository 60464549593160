import React, { Component } from "react";
import { Card, CardActionArea, Typography, Grid } from "@material-ui/core";
import moment from "moment";
import {
  classificationTypeToString,
  demandTypeToString
} from "../../../../utils/utils";

export default class DemandListItem extends Component {
  render() {
    let colorToText = this.props.status !== "Arquivada" ? "secondary" : "error";

    let colorTopCard;
    switch (this.props.status) {
      case "Arquivada":
        colorTopCard = "rgba(56, 68, 89, 0.8)";
        break;
      case "Aberta":
        colorTopCard = "#64b5f6";
        break;
      case "Aceita":
        colorTopCard = "#8bc34a";
        break;
    }

    return (
      <Card 
        style={{
          backgroundColor:
            this.props.status === "Arquivada" && "rgba(255, 255, 255, 0.5)", height: "100%", width: "100%"
        }}
      >
        <CardActionArea style={{ marginBottom: "auto" }}>
          <Grid
            container
            justify="flex-end"
            style={{ backgroundColor: colorTopCard }}
          >
            <Typography
              variant="subtitle1"
              className="white-info"
              style={{ marginRight: 10, fontSize: 12 }}
            >
              {this.props.status} / {demandTypeToString(this.props.demand_type)}
            </Typography>
          </Grid>
          <Grid container spacing={8} style={{ padding: 20 }}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="primary"
                style={{ lineHeight: "1.2" }}
              >
                {this.props.title}
              </Typography>
              {/* <Typography variant='body1' style={{ minHeight: 24 }}>
                {this.props.creator_name}
              </Typography> */}
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                color={colorToText}
                style={{ fontSize: 10 }}
              >
                De
              </Typography>
              <Typography variant="body1">
                {this.props.creator_name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                color={colorToText}
                style={{ fontSize: 10 }}
              >
                Para
              </Typography>
              <Typography variant="body1">
                {demandTypeToString(this.props.demand_type) === "Pública" ? "Todos os irmãos" : this.props.requested_name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                color={colorToText}
                style={{ fontSize: 10 }}
              >
                Classificação
              </Typography>
              <Typography variant="body1">
                {classificationTypeToString(this.props.classification)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                color={colorToText}
                style={{ fontSize: 10 }}
              >
                Data de Abertura
              </Typography>
              <Typography variant="body1">
                {moment(this.props.created_at).format("DD/MM/YYYY HH:mm")}
                {/* {relativeMoment(this.props.created_at)} */}
              </Typography>
            </Grid>
            {/* <Grid item xs={false} md={4}>
              <Typography
                variant='body2'
                color={colorToText}
                style={{ fontSize: 10 }}
              >
                Estado
              </Typography>
              <Typography variant='body1'>{this.props.status}</Typography>
            </Grid> */}
          </Grid>
        </CardActionArea>
      </Card>
    );
  }
}
