import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Paper, Typography } from "@material-ui/core";
import BigButton from "../../../components/BigButton";

class NewDemandFinish extends Component {
  render() {
    return (
      <Grid item xs={11} sm={10} md={8}>
        <Paper style={{ padding: 32 }}>
          <Grid container justify='space-around' spacing={16}>
            <Grid item xs={12}>
              <Typography variant='h5' align='center' color='primary'>
                Enviada!
              </Typography>
              <Typography
                variant='h6'
                align='center'
                color='secondary'

              >
                {this.props.privacy === "public"
                  ? "Sua demanda foi publicada com sucesso."
                  : "Sua demanda foi enviada com sucesso."}
              </Typography>
              <Typography variant='body1' align='center' style={{ marginTop: "5vh" }}>
                {this.props.privacy === "public"
                  ? "Caso a sua demanda seja aceita por um ou mais irmãos, você receberá uma notificação por email."
                  : "O irmão destinatário irá receber um email de aviso. Caso a demanda seja aceita ou arquivada pelo destinatário, você receberá um aviso por email."}
              </Typography>
              <BigButton
                icon='view_headline_outlined'
                title='Visualizar Demandas'
                action={() => this.props.history.push("/demandas")}
                style={{ marginTop: "5vh" }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

export default withRouter(NewDemandFinish);
