import api from './api'
class BrotherService {

	async getAllBroters(){
		return api.get('/brother/getAll');
	}

	async getBrotherByCim(cim) {
		return api.get(`/brother/getByCim/${cim}`);
	}

	async getPhone(cim) {
		return api.get(`/brother/phone/${cim}`);
	}

	async insertPhone(cim, phone) {
		return api.post('/brother/insertPhone', {
			cim: cim,
			phone: phone,
		});
	}

	async searchBrothers(search) {
		return api.post('/brother/search', {
			search
		});
	}
}

export default new BrotherService()
