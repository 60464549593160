import React, { Component, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Grid,
  Link,
  MenuItem,
  CircularProgress,
  Paper,
  Tabs,
  Tab,
  Hidden,
  TextField,
  Typography,
} from "@material-ui/core";
import DemandListItem from "./subcomponent/DemandListItem";

import Pagination from "material-ui-flat-pagination";

import DemandService from "../../../services/DemandService";
import { getUser } from "../../../services/auth";
// import {
//   isDemandPublic,
//   isDemandReceived,
//   isDemandMine,
//   isDemandArchived
// } from "../../../utils/utils";

import _ from "lodash";

export default class DemandList extends Component {
  state = {
    tabFilter: "publics",
    demands: [],
    errorService: false,
    loading: true,
    user: getUser(),

    // Demands
    publicDemands: [],
    receivedDemands: [],
    mineDemands: [],
    archivedDemands: [],

    // Tabs counter
    tabPublicDemands: 0,
    tabReceivedDemands: 0,
    tabMineDemands: 0,

    // Demands shown on screen
    demandsFiltered: [],

    // Paginacao
    pageTotalItems: 0,
    pageOffset: 0,
    itemsPerPage: 10
  };

  componentWillMount() {
    this.getAllData();

    if (this.props.location.state && this.props.location.state.tabFilter)
      this.setState({ tabFilter: this.props.location.state.tabFilter });

    // sleep(1000);

    let archived = _.union(this.state.mineDemands, this.state.receivedDemands);
    archived = _.filter(archived, demand => {
      if (demand.status === "Arquivada") return demand;
    });

    this.setState({
      archivedDemands: archived,
      loading: false
    });
  }

  async getAllData() {
    await DemandService.getAllPublicDemands().then(response => {
      if (response.status !== 200 || !response.data.success) {
        this.setState({ errorService: true });
        return;
      }

      this.setState(
        {
          publicDemands: response.data.result
        },
        function() {
          this.filterDemands();
        }
      );
    });

    await DemandService.getDemandByRequested(this.state.user).then(response => {
      if (response.status !== 200 || !response.data.success) {
        this.setState({ errorService: true });
        return;
      }
      this.setState(
        {
          receivedDemands: response.data.result
        },
        function() {
          this.filterDemands();
        }
      );
    });

    await DemandService.getDemandByCreator(this.state.user).then(response => {
      if (response.status !== 200 || !response.data.success) {
        this.setState({ errorService: true });
        return;
      }
      this.setState(
        {
          mineDemands: response.data.result
        },
        function() {
          this.filterDemands();
        }
      );
    });
  }

  filterDemands() {
    // let demandsFiltered = this.state.demands.filter((demand, index) => {
    //   let isPublic =
    //     this.state.tabFilter === "publics" && isDemandPublic(demand);
    //   let isReceived =
    //     this.state.tabFilter === "received" && isDemandReceived(demand);
    //   let isMine =
    //     this.state.tabFilter === "mine" &&
    //     isDemandMine(demand, this.state.user);
    //   let isArchived =
    //     this.state.tabFilter === "archived" && isDemandArchived(demand);

    //   return isPublic || isReceived || isMine || isArchived;
    // });

    let demandsFiltered = [];

    switch (this.state.tabFilter) {
      case "received":
        demandsFiltered = _.filter(this.state.receivedDemands, demand => {
          return demand.status !== "Arquivada";
        });
        break;
      case "mine":
        demandsFiltered = _.filter(this.state.mineDemands, demand => {
          return demand.status !== "Arquivada";
        });
        break;
      case "archived":
        let mineFiltered = _.filter(this.state.mineDemands, demand => {
          return demand.status === "Arquivada";
        });
        let receivedFiltered = _.filter(this.state.receivedDemands, demand => {
          return demand.status === "Arquivada";
        });
        demandsFiltered = mineFiltered.concat(receivedFiltered);
        break;
      case "publics":
      default:
        demandsFiltered = this.state.publicDemands;
        break;
    }

    if (demandsFiltered && demandsFiltered.length > 0) {
      demandsFiltered = demandsFiltered.map((demand, index) => {
        return (
          <Grid
            item
            xs={11}
            sm={10}
            md={5}
            lg={5}
            style={{ marginTop: "5vh" }}
            key={index}
          >
            <Link
              component={RouterLink}
              to={"/demanda/" + demand.id}
              underline="none"
            >
              <DemandListItem {...demand} key={index} />
            </Link>
          </Grid>
        );
      });
    }

    this.setState({
      demandsFiltered,
      pageTotalItems: demandsFiltered ? demandsFiltered.length : 0
    });
  }

  render() {
    let lowerCut = this.state.pageOffset;
    let upperCut = this.state.pageOffset + this.state.itemsPerPage;
    let demandsSliced = [];

    if (this.state.demandsFiltered) {
      demandsSliced = this.state.demandsFiltered.slice(lowerCut, upperCut);
    }
    return (
      <Fragment>
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ paddingTop: "5vh" }}
        >
          <Grid item xs={11} sm={10} md={10} lg={8}>
            <Hidden smDown>
              <Paper>
                <Tabs
                  value={this.state.tabFilter}
                  onChange={(event, value) =>
                    this.setState(
                      { tabFilter: value, pageOffset: 0 },
                      function() {
                        this.filterDemands();
                      }
                    )
                  }
                  indicatorColor="secondary"
                  textColor="primary"
                  scrollButtons="on"
                  variant="fullWidth"
                >
                  <Tab
                    label="Demandas Públicas"
                    value="publics"
                  />
                  <Tab label="Recebidas/Aceitas" value="received" />
                  <Tab label="Minhas Demandas" value="mine" />
                  <Tab label="Arquivadas" value="archived" />
                </Tabs>
              </Paper>
            </Hidden>
            <Hidden mdUp>
              {/* <InputLabel htmlFor='age-simple'>Filtros</InputLabel> */}
              <TextField
                select
                label="Filtrar por:"
                value={this.state.tabFilter}
                onChange={(event, value) => {
                  this.setState(
                    {
                      tabFilter: event.target.value,
                      pageOffset: 0
                    },
                    function() {
                      this.filterDemands();
                    }
                  );
                }}
                inputProps={{
                  name: "tabFilter",
                  id: "tabFilter"
                }}
                margin="normal"
                variant="filled"
                fullWidth
              >
                <MenuItem value="publics">Demandas Públicas</MenuItem>
                <MenuItem value="received">Recebidas/Aceitas</MenuItem>
                <MenuItem value="mine">Minhas Demandas</MenuItem>
                <MenuItem value="archived">Arquivadas</MenuItem>
              </TextField>
            </Hidden>
          </Grid>
        </Grid>
        <Grid container alignItems="stretch" justify="space-evenly">
          {this.state.loading && (
            <CircularProgress color="secondary" style={{ margin: 30 }} />
          )}
          {!this.state.loading && demandsSliced}
          {!this.state.loading && demandsSliced.length === 0 && (
            <Typography
              variant="h6"
              justify="center"
              style={{ margin: 32, color: "rgba(0, 0, 0, 0.6)" }}
            >
              Sem demandas nesta categoria
            </Typography>
          )}
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          justify="center"
          style={{ paddingBottom: "5vh" }}
        >
          {!this.state.loading && (
            <Grid item xs={12} sm={6} md={4} container justify="center" >
              <Pagination
                limit={this.state.itemsPerPage}
                offset={this.state.pageOffset}
                total={this.state.pageTotalItems}
                onClick={(e, offset) => this.setState({ pageOffset: offset })}
                style={{ marginTop: "5vh" }}
                size="large"
              />
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  }
}
