import React, { Component } from "react";
import {
  Card,
  Typography,
  CardActionArea,
  Icon,
  Grid
} from "@material-ui/core";

export default class BigButton extends Component {
  render() {
    return (
      <Card style={{ backgroundColor: "#FF9F1C", marginTop: 32 }} square>
        <CardActionArea>
          <Grid
            container
            justify='center'
            alignItems='center'
            style={{ padding: 16, height: "100%" }}
            onClick={this.props.action}
          >
            <Icon className='white-info'>{this.props.icon}</Icon>
            <Typography
              variant='h6'
              className='white-info'
              style={{ marginLeft: 16 }}
            >
              {this.props.title}
            </Typography>
          </Grid>
        </CardActionArea>
      </Card>
    );
  }
}
