import React, { Component } from "react";
import { Typography, Grid } from "@material-ui/core";

export default class Disclaimer extends Component {
  render() {
    return (
      <Grid
        container
        item
        xs={10}
        sm={8}
        md={6}
        style={{ marginTop: "5vh", marginBottom: "5vh" }}
      >
        <Typography variant='subtitle1' align='center'>
          Lembre-se que os dados de contato aqui exibidos são provenientes do
          banco de dados do CIM do GOSC, podendo estar desatualizados ou até não
          mais pertencer a pessoa original. Tenha discreção e cuidado ao entrar
          em contato.
        </Typography>
      </Grid>
    );
  }
}
