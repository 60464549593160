import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import logoGosc from "../../assets/images/logo_gosc.png";

export default class AppTopBar extends Component {
  render() {
    return (
      <AppBar position="static">
        <Toolbar>
          <Grid container justify="space-between" alignItems="center">
            <Link to="/">
              <IconButton
                color="inherit"
                aria-label="Menu"
                // onClick={this.props.handleDrawerOpen}
                style={{ width: 50 }}
              >
                <HomeIcon className="white-info"/>
              </IconButton>
            </Link>
            <Typography variant="h6" color="inherit" justify="center">
              Hospitalaria GOSC
            </Typography>
            <Link to="/">
              <img
                style={{
                  width: "auto",
                  height: 50,
                  marginTop: 10,
                  marginBottom: 10
                }}
                src={logoGosc}
                alt="Logo GOSC"
              />
            </Link>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}
