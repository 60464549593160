import React, { Component } from "react";
import {
  Card,
  Grid,
  CardActionArea,
  Icon,
  Typography
} from "@material-ui/core";

export default class ContactCard extends Component {
  render() {
    let color;
    switch (this.props.color) {
      case "blue":
        color = "#003158";
        break;
      case "gray":
        color = "rgba(56, 68, 89, 0.8)";
        break;
      default:
      case "orange":
        color = "#FF9F1C";
        break;
    }

    return (
      <Card
        style={{
          backgroundColor: color
        }}
        square
      >
        <CardActionArea>
          <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
            style={{ padding: 32, height: "100%" }}
          >
            <Icon className='white-info'>{this.props.icon}</Icon>
            <Typography className='white-info' variant='h3' align='center'>
              {this.props.text || ""}
            </Typography>
          </Grid>
        </CardActionArea>
      </Card>
    );
  }
}
