import React, { Component, Fragment } from "react";
import {
  Grid,
  TextField,
  Button,
  Snackbar,
  Paper,
  Typography
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import logoGosc from "../../../assets/images/logo_gosc.png";
import ExternalLogin from "../../../services/ExternalLogin";
import api from "../../../services/api";
import { login as loginUtil, setUser } from "../../../services/auth";
import { USER } from "../../../utils/utils";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      password: "",
      open: false,
      vertical: "top",
      horizontal: "center",
      message: "",

      disabledButton: false
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async onLogin(e) {
    e.preventDefault();

    this.setState({ disabledButton: true });

    const { login, password } = this.state;

    try {
      let authResponse = await ExternalLogin.login(login, password);

      if (authResponse.data.result === "true") {
        const response = await api.post("/token", {
          email: USER.email,
          password: USER.password
        });

        loginUtil(response.data.token);
        setUser(parseInt(login));

        this.props.history.push({
          pathname: "/home",
          state: {
            user_cim: login
          }
        });
      } else {
        this.setState({
          open: true,
          message:
            authResponse.data.result === false
              ? "Senha e/ou login iválidos"
              : authResponse.data.result
        });
      }
    } catch (e) {
      console.log(e);
    }

    this.setState({
      login: "",
      password: "",
      disabledButton: false
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  onInputChange(e) {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    this.setState({ [inputName]: inputValue });
  }

  render() {
    const { vertical, horizontal, open } = this.state;

    return (
      <Fragment>
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ marginTop: "5vh", marginBottom: "5vh" }}
        >
          <Grid item xs={11} sm={6} md={6}>
            <Grid container alignItems="center" justify="center" item>
              <img src={logoGosc} alt="Logo GOSC" height="200px" width="auto" />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ marginBottom: "5vh" }}
        >
          {/* <Grid item xs={10}>
              <Typography className='white-info' variant='h2' align='center'>
                Hospitalaria
              </Typography>
            </Grid> */}
          <Grid item xs={11} sm={6} md={6}>
            <Paper style={{ padding: 32 }}>
              <form onSubmit={this.onLogin}>
                <TextField
                  name={"login"}
                  id="login"
                  label="Informe seu CIM"
                  value={this.state.login}
                  margin="normal"
                  variant="outlined"
                  onChange={this.onInputChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
                <TextField
                  name={"password"}
                  id="password"
                  label="Informe sua senha do GOSC"
                  value={this.state.password}
                  margin="normal"
                  type="password"
                  variant="outlined"
                  onChange={this.onInputChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  fullWidth
                  size="large"
                  style={{ marginTop: 32, padding: 16 }}
                  disabled={this.state.disabledButton}
                >
                  {!this.state.disabledButton ? "Acessar" : "Carregando"}
                </Button>
              </form>
              <Typography
                style={{
                  marginTop: 8
                }}
              >
                *se não lembra sua senha, redefina-á através do site{" "}
                <a href={"https://gosc.org.br"}>gosc.org.br</a>
              </Typography>
            </Paper>
          </Grid>

          <Snackbar
            anchorOrigin={{
              vertical,
              horizontal
            }}
            open={open}
            onClose={this.handleClose}
            TransitionComponent={Fade}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">CIM ou senha incorretos</span>}
          />
        </Grid>
      </Fragment>
    );
  }
}
