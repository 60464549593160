// import moment from "moment";

export const USER = {
  email: "gosc@gosc.org.br",
  password: "gosc@2019"
};

export function classificationTypeToString(classification) {
  switch (classification) {
    case "masonic":
      return "De ordem maçônica";
    case "professional":
      return "De ordem profissional ou associada ao trabalho";
    case "services":
      return "Demanda por serviços ou produtos em geral";
    case "health":
      return "De ordem médica ou associada à saúde";
    case "juridic":
      return "De ordem jurídica ou associada à justiça";
    case "personal":
      return "De ordem pessoal ou familiar";
    default:
      return "Outros";
  }
}

export function demandTypeToString(demand_type) {
  switch (demand_type) {
    case 1:
      return "Pública";
    case 2:
      return "Privada";
    default:
      return "Outra";
  }
}

export function isDemandPublic(demand) {
  return demand.type === "Pública" && demand.status === "Aberta";
}

export function isDemandReceived(demand) {
  return demand.type === "Privada" && demand.status !== "Arquivada";
}

export function isDemandMine(demand, user) {
  return demand.creator_id === user && demand.status !== "Arquivada";
}

export function isDemandArchived(demand) {
  return demand.status === "Arquivada";
}

export function demandType(demand, user) {
  if (isDemandArchived(demand)) return "Arquivada";

  if (isDemandPublic(demand)) return "Pública";

  if (isDemandReceived(demand)) return "Received";

  if (isDemandMine(demand, user)) return "Criada por mim";
}

// export function relativeMoment(time) {
//   moment.updateLocale("pt-Br", {
//     relativeTime: {
//       future: "Em %s",
//       past: "%s atrás",
//       s: "A alguns segundos",
//       ss: "%d segundos",
//       m: "Um minuto",
//       mm: "%d minutos",
//       h: "1 hora atrás",
//       hh: "%d horas atrás",
//       d: "1 dia atrás",
//       dd: "%d dias atrás",
//       M: "1 mês atrás",
//       MM: "%d meses atrás",
//       y: "Um ano atrás",
//       yy: "%d anos atrás"
//     }
//   });
//   return moment(time)
//     .locale("pt-Br")
//     .fromNow();
// }
