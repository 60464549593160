import React, { Component } from "react";
import {
  Card,
  Typography,
  CardActionArea,
  Icon,
  Grid
} from "@material-ui/core";

export default class BigButtonWithLoading extends Component {
  render() {
    let color = "#FF9F1C";
    let title = this.props.title;
    let icon = this.props.icon;
    switch (this.props.status) {
      case "loading":
        color = "rgba(0, 0, 0, 0.12)";
        title = this.props.loadingTitle;
        icon = this.props.icon;
        break;
      case "success":
        color = "rgba(77, 184, 72, 0.87)";
        title = this.props.successTitle;
        icon = 'check-outlined'
        break;
      default:
        color = "#FF9F1C";
        title = this.props.title;
        icon = this.props.icon;
    }
    

    return (
      <Card style={{ backgroundColor: color, marginTop: 32 }} square>
        <CardActionArea>
          <Grid
            container
            justify='center'
            alignItems='center'
            style={{ padding: 16, height: "100%" }}
            onClick={this.props.action}
          >
            <Icon className='white-info'>{icon}</Icon>
            <Typography
              variant='h6'
              className='white-info'
              style={{ marginLeft: 16 }}
            >
              {title}
            </Typography>
          </Grid>
        </CardActionArea>
      </Card>
    );
  }
}
