import React, { Component, Fragment } from "react";
import {
  Paper,
  Grid,
  Typography,
  Divider,
  Avatar,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  withMobileDialog,
  TextField,
  MenuItem,
  Button
} from "@material-ui/core";
import ContactCard from "../DemandList/subcomponent/ContactCard";
import ArrowFoward from "@material-ui/icons/ArrowForwardIosOutlined";
import Public from "@material-ui/icons/PublicOutlined";
import Person from "@material-ui/icons/PersonOutlined";
import * as _ from "lodash";

import DemandService from "../../../services/DemandService";
import BrotherService from "../../../services/BrotherService";
import Disclaimer from "../../components/Disclaimer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  classificationTypeToString,
  demandTypeToString
} from "../../../utils/utils";

import { getUser, getUsername } from "../../../services/auth";

import moment from "moment";

var ResponsiveDialog = withMobileDialog()(Dialog);

export default class DemandView extends Component {
  state = {
    demand: [],
    demandant: [],
    demandantPhone: false,

    requested: [],
    requestedPhone: false,
    errorService: false,

    demandUsers: [],

    snackbarOpenPhone: false,
    snackbarOpenEmail: false,

    archivedModal: false,
    acceptModal: false,
    archivingReason: "",
    archivingText: "",

    myUserId: getUser(),
    myUserName: getUsername(),
    demandIsMine: false
  };

  componentWillMount() {
    this.getData();
  }

  getData() {
    DemandService.getDemandById(this.props.match.params.id).then(response => {
      if (response.status !== 200 || !response.data.success) {
        this.setState({ errorService: true });
        return;
      }

      let demandIsMine =
        this.state.myUserId === response.data.result.demand.creator_id;

      this.setState(
        {
          demand: response.data.result.demand,
          demandUsers: response.data.result.demandUsers,
          demandIsMine: demandIsMine
        },
        // Callback
        () => {
          // After getting the demand
          BrotherService.getBrotherByCim(
            response.data.result.demand.creator_id
          ).then(response => {
            if (response.status !== 200 || !response.data.success) {
              this.setState({ errorService: true });
              return;
            }

            this.setState({
              demandant: response.data.result
            });
          });

          BrotherService.getPhone(response.data.result.demand.creator_id).then(
            response => {
              if (response.status !== 200 || !response.data.success) {
                this.setState({ demandantPhone: false });
                return;
              }

              this.setState({
                demandantPhone: response.data.result.phone
              });
            }
          );

          // GET THE REQUESTED DATA
          if (demandTypeToString(this.state.demand.demand_type) === "Privada") {
            // Requested
            BrotherService.getBrotherByCim(
              response.data.result.demandUsers[0].requested_id
            ).then(response => {
              if (response.status !== 200 || !response.data.success) {
                this.setState({ errorService: true });
                return;
              }
              this.setState({
                requested: response.data.result
              });
            });

            BrotherService.getPhone(
              response.data.result.demandUsers[0].requested_id
            ).then(response => {
              if (response.status !== 200 || !response.data.success) {
                this.setState({ requestedPhone: false });
                return;
              }
              this.setState({
                requestedPhone: response.data.result.phone
              });
            });
          }
        }
      );
    });
  }

  renderOpenDemand() {
    if (this.state.demandIsMine) return;

    return (
      <Grid item xs>
        <a onClick={() => this.acceptDemand()}>
          <ContactCard icon="check_outline" text="Aceitar" />
        </a>
      </Grid>
    );
  }

  acceptDemand() {
    if (this.state.demand.demand_type === 2) {
      let demandToChange = {
        status: "Aceita",
        id: this.props.match.params.id
      };

      DemandService.changeStatusDemand(demandToChange).then(response => {
        if (response.data.success) {
        }
        this.getData();
      });
    } else {
      let demand = {
        demand_id: this.props.match.params.id,
        creator_id: this.state.demand.creator_id
      };
      let user = {
        id: this.state.myUserId,
        name: this.state.myUserName
      };
      DemandService.insertDemandUser(demand, user).then(response => {
        if (response.data.success) {
        }
        this.getData();
      });
    }
    this.setState({ acceptModal: true });
  }

  openDemand() {
    let demand = {
      id: this.props.match.params.id,
      status: "Aberta"
    };
    DemandService.changeStatusDemand(demand).then(response => {
      if (response.data.success) {
      }
      this.getData();
    });
  }

  archiveDemand() {
    let filed = {
      id: this.props.match.params.id,
      text: this.state.archivingText,
      filed_by: 2,
      type: this.state.archivingReason
    };
    DemandService.fileDemand(filed).then(response => {
      if (response.data.success) {
        this.setState({
          archivedModal: false
        });
      }
      this.getData();
    });
  }

  checkDemandUsers() {
    let user = _.find(this.state.demandUsers, user => {
      return user.requested_id === parseInt(this.state.myUserId);
    });
    return !!user;
  }

  renderArchiveButton() {
    return (
      <Grid item xs>
        <a onClick={() => this.setState({ archivedModal: true })}>
          <ContactCard icon="archive_outline" text="Arquivar" color="gray" />
        </a>
      </Grid>
    );
  }

  renderArchivedDemand() {
    return (
      <Fragment>
        <Grid
          item
          xs={12}
          style={{ padding: 12, backgroundColor: "rgba(56, 68, 89, 0.8)" }}
        >
          <Typography variant="body2" align="center" className="white-info">
            Demanda arquivada em{" "}
            {moment(this.state.demand.updated_at).format("DD/MM/YYYY HH:mm")}
          </Typography>
        </Grid>
      </Fragment>
    );
  }

  renderPublicSupporters() {
    let supportersCounter = this.state.demandUsers
      ? this.state.demandUsers.length
      : 0;

    let supportersText = "";
    if (supportersCounter <= 0)
      supportersText = "Nenhum irmão ajudando esta demanda";
    if (supportersCounter === 1)
      supportersText = "Um irmão ajudando esta demanda";
    if (supportersCounter > 1)
      supportersText = `${supportersCounter} irmãos ajudando esta demanda`;

    return (
      <Grid item xs={12}>
        <Divider
          variant="fullWidth"
          style={{ marginTop: 10, marginBottom: 10 }}
        />
        <Typography variant="body2" color="secondary">
          Irmãos apoiando
        </Typography>
        <Typography variant="body1">{supportersText}</Typography>
      </Grid>
    );
  }

  renderAceptedDemandPhone() {
    if (this.state.demandIsMine && !this.state.requestedPhone) return;
    if (!this.state.demandIsMine && !this.state.demandantPhone) return;

    let targetPhone = this.state.demandIsMine
      ? this.state.requestedPhone
      : this.state.demandantPhone;

    let senderName = this.state.demandIsMine
      ? this.state.demandant.pessoa_nome
      : this.state.requested.pessoa_nome;
    let targetName = this.state.demandIsMine
      ? this.state.requested.pessoa_nome
      : this.state.demandant.pessoa_nome;

    let whatsPhone = "55" + targetPhone.replace(/[^0-9]/g, "");
    let whatsMessage = "";
    if (this.state.demand.demand_type === 1) {
      whatsMessage = encodeURI(
        `Olá Ir ${targetName} eu sou o Ir ${this.state.myUserName}. Obtive seu contato pelo sistema de Hospitalaria do GOSC.`
      );
    } else {
      whatsMessage = encodeURI(
        `Olá Ir ${targetName} eu sou o Ir ${senderName}. Obtive seu contato pelo sistema de Hospitalaria do GOSC.`
      );
    }

    const link = `https://api.whatsapp.com/send?phone=${whatsPhone}&text=${whatsMessage}`;
    return (
      <Grid item xs>
        <CopyToClipboard
          text={this.state.demandantPhone}
          onCopy={() => this.setState({ snackbarOpenPhone: true })}
        >
          <a href={link} style={{ textDecoration: "none" }} target="_blank">
            <ContactCard icon="phone" text="Telefone" color="blue" />
          </a>
        </CopyToClipboard>
      </Grid>
    );
  }

  renderAceptedDemandEmail() {
    if (this.state.demandIsMine) return;

    return (
      <Grid item xs>
        <CopyToClipboard
          text={this.state.demandant.pessoa_email}
          onCopy={() => this.setState({ snackbarOpenEmail: true })}
        >
          <a>
            <ContactCard icon="mail_outline" text="Email" color="blue" />
          </a>
        </CopyToClipboard>
      </Grid>
    );
  }

  renderCreatorData(colorToText) {
    return (
      <Fragment>
        <Grid item xs={12}>
          <Divider variant="fullWidth" style={{ marginTop: 10 }} />
        </Grid>
        <Grid item xs={8} md={4} style={{ marginTop: 10 }}>
          <Typography variant="body2" color={colorToText}>
            Criada pelo Irmão
          </Typography>
          <Typography variant="body1">
            {this.state.demandant.pessoa_nome}
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} style={{ marginTop: 10 }}>
          <Typography variant="body2" color={colorToText}>
            Idade
          </Typography>
          <Typography variant="body1">{this.state.demandant.idade}</Typography>
        </Grid>
        <Grid item xs={12} md={4} style={{ marginTop: 10 }}>
          <Typography variant="body2" color={colorToText}>
            Membro da
          </Typography>

          <Typography variant="body1">
            {`${this.state.demandant.loja_prefixo_nome}  ${this.state.requested.loja_nome}`}
          </Typography>
        </Grid>
      </Fragment>
    );
  }

  renderRequestedData(colorToText) {
    return (
      <Fragment>
        <Grid item xs={12}>
          <Divider variant="fullWidth" style={{ marginTop: 10 }} />
        </Grid>
        <Grid item xs={8} md={4} style={{ marginTop: 10 }}>
          <Typography variant="body2" color={colorToText}>
            Para o Irmão
          </Typography>
          <Typography variant="body1">
            {this.state.requested.pessoa_nome}
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} style={{ marginTop: 10 }}>
          <Typography variant="body2" color={colorToText}>
            Idade
          </Typography>
          <Typography variant="body1">{this.state.requested.idade}</Typography>
        </Grid>
        <Grid item xs={12} md={4} style={{ marginTop: 10 }}>
          <Typography variant="body2" color={colorToText}>
            Membro da
          </Typography>
          <Typography variant="body1">
            {`${this.state.requested.loja_prefixo_nome}  ${this.state.requested.loja_nome}`}
          </Typography>
        </Grid>
      </Fragment>
    );
  }

  renderSubtitle() {
    if (
      this.state.demandIsMine &&
      demandTypeToString(this.state.demand.demand_type) !== "Pública"
    )
      return "Criada por mim";
    if (
      this.state.demandIsMine &&
      demandTypeToString(this.state.demand.demand_type) === "Pública"
    )
      return "Criada por eu para todos os irmãos";
    if (
      !this.state.demandIsMine &&
      demandTypeToString(this.state.demand.demand_type) !== "Pública"
    )
      return "Para mim";
    if (
      !this.state.demandIsMine &&
      demandTypeToString(this.state.demand.demand_type) === "Pública"
    )
      return "Para todos os irmãos";
  }

  renderRequestedAvatar() {
    if (demandTypeToString(this.state.demand.demand_type) === "Pública")
      return (
        <Avatar
          style={{
            width: 100,
            height: 100,
            backgroundColor: "#003158"
          }}
        >
          <Public
            style={{
              width: 70,
              height: 70
            }}
          />
        </Avatar>
      );

    if (this.state.requested && this.state.requested.foto) {
      return (
        <Avatar
          src={this.state.requested.foto ? this.state.requested.foto : ""}
          style={{
            width: 100,
            height: 100
          }}
        />
      );
    } else {
      return (
        <Avatar
          style={{
            width: 100,
            height: 100,
            backgroundColor: "#003158"
          }}
        >
          <Person
            style={{
              width: 70,
              height: 70
            }}
          />
        </Avatar>
      );
    }
  }

  render() {
    let colorToText =
      this.state.demand.status !== "Arquivada" ? "secondary" : "error";

    let colorTopCard;
    switch (this.state.demand.status) {
      case "Arquivada":
        colorTopCard = "rgba(56, 68, 89, 0.8)";
        break;
      case "Aberta":
        colorTopCard = "#64b5f6";
        break;
      case "Aceita":
        colorTopCard = "#8bc34a";
        break;
    }

    return (
      <Grid container alignItems="flex-start" justify="center">
        <Grid
          item
          xs={11}
          sm={10}
          lg={9}
          container
          justify="center"
          style={{ marginTop: "5vh" }}
        >
          <Grid item container justify="center" alignItems="center">
            <Avatar
              src={this.state.demandant.foto ? this.state.demandant.foto : ""}
              style={{
                width: 100,
                height: 100
              }}
            />
            <ArrowFoward className="orange-info" />
            {this.renderRequestedAvatar()}
          </Grid>
        </Grid>
        <Grid item xs={11} sm={9} style={{ marginTop: "5vh" }}>
          <Paper
            style={{
              backgroundColor:
                this.state.demand.status === "Arquivada" &&
                "rgba(255, 255, 255, 0.5)"
            }}
          >
            <Grid
              container
              justify="flex-end"
              style={{ backgroundColor: colorTopCard }}
            >
              <Typography
                variant="subtitle1"
                className="white-info"
                style={{ marginRight: 10, fontSize: 12 }}
              >
                {this.state.demand.status} /{" "}
                {demandTypeToString(this.state.demand.demand_type)}
              </Typography>
            </Grid>
            <Grid container spacing={8} style={{ padding: 20 }}>
              <Grid
                item
                xs={12}
                container
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h1" color="primary">
                    {this.state.demand.title}
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {classificationTypeToString(
                      this.state.demand.classification
                    )}
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {this.renderSubtitle()}
                  </Typography>
                </Grid>
              </Grid>
              {!this.state.demandIsMine && this.renderCreatorData(colorToText)}
              {this.state.demandIsMine &&
                demandTypeToString(this.state.demand.demand_type) !==
                  "Pública" &&
                this.renderRequestedData(colorToText)}

              {demandTypeToString(this.state.demand.demand_type) ===
                "Pública" && this.renderPublicSupporters()}

              <Grid item xs={12}>
                <Divider
                  variant="fullWidth"
                  style={{ marginTop: 10, marginBottom: 10 }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body2" color={colorToText}>
                  Data de Criação
                </Typography>
                <Typography variant="body1">
                  {moment(this.state.demand.created_at).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color={colorToText}>
                  Última Alteração
                </Typography>
                <Typography variant="body1">
                  {this.state.demand.updated_at
                    ? moment(this.state.demand.updated_at).format(
                        "DD/MM/YYYY HH:mm"
                      )
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  variant="fullWidth"
                  style={{ marginTop: 10, marginBottom: 10 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" align="justify">
                  {this.state.demand.description}
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 32 }}>
              {this.state.demand.status !== "Arquivada" &&
                this.state.demand.demand_type === 2 &&
                this.renderArchiveButton()}
              {this.state.demand.status === "Aberta" &&
                this.state.demand.demand_type === 2 &&
                this.checkDemandUsers() &&
                this.renderOpenDemand()}
              {this.state.demand.status === "Aceita" &&
                this.state.demand.demand_type === 2 &&
                this.checkDemandUsers() &&
                this.state.demandant.pessoa_email &&
                this.renderAceptedDemandEmail()}
              {this.state.demand.status === "Aceita" &&
                this.state.demand.demand_type === 2 &&
                this.renderAceptedDemandPhone()}

              {this.state.demand.status === "Aberta" &&
                !this.checkDemandUsers() &&
                this.renderOpenDemand()}
              {this.state.demand.status === "Aberta" &&
                this.state.demand.demand_type === 1 &&
                this.checkDemandUsers() &&
                this.renderAceptedDemandPhone()}
              {this.state.demand.status === "Aberta" &&
                this.state.demand.demand_type === 1 &&
                this.state.demandant.pessoa_email &&
                this.renderAceptedDemandEmail()}
              {this.state.demand.status === "Arquivada" &&
                this.renderArchivedDemand()}
            </Grid>
          </Paper>
        </Grid>
        <Disclaimer />

        {/* Avisos de Cópia de contato */}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={this.state.snackbarOpenEmail}
          autoHideDuration={5000}
          onClose={() => {
            this.setState({ snackbarOpenEmail: false });
          }}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={
            <span id="message-id">
              <Typography color={colorToText} component="span">
                {this.state.demandant.email}
              </Typography>
              O email do irmão {this.state.demandant.nome} foi copiado para a
              Área de Transferência
            </span>
          }
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={this.state.snackbarOpenPhone}
          autoHideDuration={5000}
          onClose={() => {
            this.setState({ snackbarOpenPhone: false });
          }}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={
            <span id="message-id">
              <Typography color={colorToText} component="span">
                {this.state.demandantPhone}
              </Typography>
              O telefone do irmão {this.state.demandantPhone} foi copiado para a
              Área de Transferência
            </span>
          }
        />

        {/* Modal de Arquivamento */}
        <ResponsiveDialog
          aria-labelledby="form-dialog-title"
          aria-describedby="simple-modal-description"
          open={this.state.archivedModal}
          onClose={() => this.setState({ archivedModal: false })}
        >
          <DialogTitle
            id="form-dialog-title"
            disableTypography
            style={{ borderTop: "solid 10px #003158", borderRadius: "4px" }}
          >
            <Typography color={colorToText} variant="h1">
              Você tem certeza que deseja arquivar esta demanda?
            </Typography>
            <Typography variant="h3">
              Por favor, nos informe o motivo:
            </Typography>
          </DialogTitle>
          <DialogContent>
            <TextField
              select
              id="archivingReason"
              label="Motivo do arquivamento"
              value={this.state.archivingReason}
              variant="filled"
              fullWidth
              onChange={e => this.setState({ archivingReason: e.target.value })}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem key="solved" value="Demanda atendida">
                Demanda atendida
              </MenuItem>
              <MenuItem key="notsolved" value="Demanda não atendida">
                Demanda não atendida
              </MenuItem>
              <MenuItem
                key="unecessary"
                value="Demanda não mais existente ou necessária"
              >
                Demanda não mais existente ou necessária
              </MenuItem>
              <MenuItem key="other" value="Outros Motivos">
                Outros Motivos
              </MenuItem>
            </TextField>
            <TextField
              id="archivingText"
              label="Descrição"
              value={this.state.archivingText}
              variant="filled"
              fullWidth
              onChange={e => this.setState({ archivingText: e.target.value })}
              style={{ marginTop: 16 }}
              InputLabelProps={{ shrink: true }}
            />
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  onClick={() => this.setState({ archivedModal: false })}
                  color={colorToText}
                  fullWidth
                  size="large"
                  style={{ marginTop: 16, padding: 16 }}
                >
                  Não
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  onClick={() => this.archiveDemand()}
                  color="primary"
                  fullWidth
                  size="large"
                  style={{ marginTop: 16, padding: 16 }}
                  disabled={
                    !this.state.archivingReason && !this.state.archivingText
                  }
                >
                  Sim
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </ResponsiveDialog>
        <ResponsiveDialog
          aria-labelledby="form-dialog-title"
          aria-describedby="simple-modal-description"
          open={this.state.acceptModal}
          onClose={() => this.setState({ acceptModal: false })}
        >
          <DialogTitle
            id="form-dialog-title"
            disableTypography
            style={{ borderTop: "solid 10px #003158", borderRadius: "4px" }}
          >
            <Typography color={colorToText} variant="h1">
              Demanda aceita
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Typography variant="h3">
              O Demandante já foi avisado sobre seu aceite,
            </Typography>
            <Typography variant="h3">
              entretanto você pode entrar em contato com ele através de uma
              da(s) opção(ões).
            </Typography>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() => this.setState({ acceptModal: false })}
                  color={colorToText}
                  fullWidth
                  size="large"
                  style={{ marginTop: 16, padding: 16 }}
                >
                  Ok
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </ResponsiveDialog>
      </Grid>
    );
  }
}
