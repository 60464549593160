import React, { Component } from "react";
import {
  Card,
  CardActionArea,
  Typography,
  Grid,
  Link
} from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";

export default class BrotherListItem extends Component {
  render() {
    return (
      <Card style={{ height: "100%", width: "100%" }}>
        <Link
          component={RouterLink}
          to={"/irmao/" + this.props.irmao_cim}
          underline='none'
        >
          <CardActionArea style={{ marginBottom: "auto" }}>
            <Grid
              container
              spacing={8}
              style={{ padding: 20 }}
              alignItems='stretch'
            >
              <Grid item xs={12}>
                <Typography
                  variant='h6'
                  color='primary'
                  style={{ lineHeight: "1.2" }}
                >
                  {this.props.pessoa_nome}
                </Typography>
                <Typography variant='body1'>
                  {this.props.loja_prefixo_nome + " " + this.props.loja_nome}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography
                  variant='body2'
                  color='secondary'
                  style={{ fontSize: 10 }}
                >
                  Profissão
                </Typography>
                <Typography variant='body1'>
                  {this.props.pessoa_ocupacao || "--"}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography
                  variant='body2'
                  color='secondary'
                  style={{ fontSize: 10 }}
                >
                  Formação
                </Typography>
                <Typography variant='body1'>
                  {this.props.pessoa_formacao || "--"}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography
                  variant='body2'
                  color='secondary'
                  style={{ fontSize: 10 }}
                >
                  Empresa/Local de Trabalho
                </Typography>
                <Typography variant='body1'>
                  {this.props.pessoa_onde_exerce || "--"}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography
                  variant='body2'
                  color='secondary'
                  style={{ fontSize: 10 }}
                >
                  Idade
                </Typography>
                <Typography variant='body1'>
                  {this.props.idade || "--"}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography
                  variant='body2'
                  color='secondary'
                  style={{ fontSize: 10 }}
                >
                  Grau
                </Typography>
                <Typography variant='body1'>
                  {this.props.grau || "--"}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography
                  variant='body2'
                  color='secondary'
                  style={{ fontSize: 10 }}
                >
                  Situação GOSC
                </Typography>
                <Typography variant='body1'>
                  {this.props.situacao || "--"}
                </Typography>
              </Grid>
            </Grid>
          </CardActionArea>
        </Link>
      </Card>
    );
  }
}
