export const TOKEN_KEY = "@GOSC_HOSPITALARIA";
export const USER_ID = "@GOSC_USER";
export const USER_NAME = "@GOSC_USERNAME";
export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => sessionStorage.getItem(TOKEN_KEY);
export const login = token => {
  sessionStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  // sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.clear();
};

export const getUser = () => sessionStorage.getItem(USER_ID);
export const setUser = id => {
  sessionStorage.setItem(USER_ID, id);
};

export const getUsername = () => sessionStorage.getItem(USER_NAME);
export const setUsername = name => {
  sessionStorage.setItem(USER_NAME, name);
};
