import React, { Component, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Link } from "@material-ui/core";
import NewDemandSplash from "./subcomponent/NewDemandSplash";
import NewDemandForm from "./subcomponent/NewDemandForm";
import NewDemandFinish from "./subcomponent/NewDemandFinish";

import DemandService from "../../../services/DemandService";
import BrotherService from "../../../services/BrotherService";
import { getUser, getUsername } from "../../../services/auth";

export default class DemandCreation extends Component {
  state = {
    step: 0,
    privacy: "",
    classification: "masonic",
    title: "",
    description: "",
    status: "",
    user: getUser(),

    errorTitle: false,
    errorDescription: false,

    // Private
    destinatary: "",
    brother: []
  };

  componentWillMount() {
    // Check if there is a CIM at URL
    if (this.props.match.params.cim) {
      BrotherService.getBrotherByCim(this.props.match.params.cim).then(
        response => {
          if (response.status === 200) {
            this.setState({
              step: 1,
              privacy: "private",
              brother: response.data.result,
              destinatary: this.props.match.params.cim
            });
          }
        }
      );
    }
  }

  renderStepDemandSelection() {
    return (
      <Fragment>
        <Grid item xs={11} sm={10} md={8}>
          <Link component={RouterLink} to={"/buscaIrmao"} underline='none'>
            <NewDemandSplash
              title='Demanda Privada'
              description='Direcionada a um Irmão em específico. Somente o destinatário visualiza a demanda.'
              color='#003158'
              action={() => this.handleStepDemandSelection("private")}
            />
          </Link>
        </Grid>
        <Grid item xs={11} sm={10} md={8} style={{ marginTop: "5vh" }}>
          <NewDemandSplash
            title='Demanda Pública'
            description='Direcionada a todos os Irmãos do GOSC. Todos podem visualizar a demanda.'
            color='#FF9F1C'
            action={() => this.handleStepDemandSelection("public")}
          />
        </Grid>
      </Fragment>
    );
  }

  handleStepDemandSelection(privacy) {
    this.setState({
      step: 1,
      privacy
    });
  }

  handleStepDemandForm = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  sendDemand = () => {
    this.setState({ status: "loading" });

    let demand;

    if (this.state.privacy === "public") {
      demand = {
        title: this.state.title,
        description: this.state.description,
        creator_id: getUser(),
        creator_name: getUsername(),
        classification: this.state.classification
      };

      DemandService.insertPublicDemand(demand).then(response => {
        if (response.status === 200) {
          this.setState({
            status: "success",
            classification: "masonic",
            title: "",
            description: "",
            step: 2
          });
        }
      });
      return;
    }

    if (this.state.privacy === "private" && this.state.destinatary) {
      demand = {
        title: this.state.title,
        description: this.state.description,
        creator_id: getUser(),
        creator_name: getUsername(),
        classification: this.state.classification
      };

      const user = {
        id: this.state.brother.irmao_cim,
        requested_name: this.state.brother.pessoa_nome
      };

      DemandService.insertPrivateDemand(demand, user).then(response => {
        if (response.status === 200) {
          this.setState({
            status: "success",
            classification: "masonic",
            title: "",
            description: "",
            step: 2
          });
        }
      });
      return;
    }
  };

  render() {
    return (
      <Fragment>
        <Grid
          container
          alignItems='center'
          justify='center'
          style={{ paddingTop: "5vh", paddingBottom: "5vh" }}
        >
          {this.state.step === 0 && this.renderStepDemandSelection()}

          {this.state.step === 1 && (
            <NewDemandForm
              {...this.state}
              handler={this.handleStepDemandForm}
              sendAction={this.sendDemand}
            />
          )}

          {this.state.step === 2 && <NewDemandFinish privacy={this.state.privacy} />}
        </Grid>
      </Fragment>
    );
  }
}
