import React, { Component, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Paper,
  Grid,
  Typography,
  CircularProgress,
  Avatar,
  Link
} from "@material-ui/core";

import ContactCard from "../DemandList/subcomponent/ContactCard";

import BrotherService from "../../../services/BrotherService";
import Disclaimer from "../../components/Disclaimer";

export default class BrotherView extends Component {
  state = {
    loading: true
  };

  componentWillMount() {
    BrotherService.getBrotherByCim(this.props.match.params.cim).then(
      response => {
        if (response.status !== 200 || !response.data.success) {
          this.setState({ errorService: true, loading: false });
          return;
        }

        this.setState({
          ...response.data.result,
          loading: false
        });
      }
    );
  }

  render() {
    return (
      <Grid container alignItems='flex-start' justify='center'>
        {this.state.loading && (
          <CircularProgress color='secondary' style={{ margin: 30 }} />
        )}

        {!this.state.loading && (
          <Fragment>
            <Grid
              item
              xs={11}
              display={{ xs: "block", md: "none" }}
              container
              direction='column'
              justify='center'
              alignItems='center'
              style={{ marginTop: "5vh" }}
            >
              <Avatar
                src={
                  this.state.foto
                    ? this.state.foto
                    : ""
                }
                style={{
                  minWidth: 150,
                  minHeight: 150,
                  width: "10vw",
                  height: "10vw",
                  maxWidth: 500,
                  maxHeight: 500,
                  marginBottom: 16
                }}
              />
              <Typography
                variant='h6'
                color='primary'
                style={{ lineHeight: "1.2" }}
              >
                {this.state.pessoa_nome || "--"}
              </Typography>
              <Typography variant='body1'>
                {this.state.loja_prefixo_nome + " " + this.state.loja_nome}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={9} style={{ marginTop: "5vh" }}>
              <Paper>
                <Grid
                  container
                  spacing={16}
                  style={{ padding: 20, marginBottom: 0 }}
                >
                  <Grid item xs={6} md={4}>
                    <Typography variant='body2' color='secondary'>
                      Profissão
                    </Typography>
                    <Typography variant='body1'>
                      {this.state.pessoa_ocupacao || "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant='body2' color='secondary'>
                      Formação
                    </Typography>
                    <Typography variant='body1'>
                      {this.state.pessoa_formacao || "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant='body2' color='secondary'>
                      Empresa/Local de Trabalho
                    </Typography>
                    <Typography variant='body1'>
                      {this.state.pessoa_onde_exerce || "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant='body2' color='secondary'>
                      Idade
                    </Typography>
                    <Typography variant='body1'>
                      {this.state.idade || "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant='body2' color='secondary'>
                      Grau
                    </Typography>
                    <Typography variant='body1'>
                      {this.state.grau || "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant='body2' color='secondary'>
                      Situação GOSC
                    </Typography>
                    <Typography variant='body1'>
                      {this.state.situacao || "--"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs>
                    <Link
                      component={RouterLink}
                      to={"/novaDemanda/" + this.state.irmao_cim}
                      underline='none'
                    >
                      <ContactCard
                        icon='add_circle_outline'
                        text='Criar Demanda Privada'
                      />
                    </Link>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Disclaimer />
            {/* <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              open={this.state.snackbarOpenEmail}
              autoHideDuration={5000}
              onClose={() => {
                this.setState({ snackbarOpenEmail: false });
              }}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={
                <span id='message-id'>
                  <Typography color='secondary' component='span'>
                    {this.state.email}
                  </Typography>
                  O email do irmão {this.state.nome} foi copiado para a Área de
                  Transferência
                </span>
              }
            />
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              open={this.state.snackbarOpenPhone}
              autoHideDuration={5000}
              onClose={() => {
                this.setState({ snackbarOpenPhone: false });
              }}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={
                <span id='message-id'>
                  <Typography color='secondary' component='span'>
                    {this.state.brotherPhone}
                  </Typography>
                  O telefone do irmão {this.state.brotherPhone} foi copiado para
                  a Área de Transferência
                </span>
              }
            /> */}
          </Fragment>
        )}
      </Grid>
    );
  }
}
