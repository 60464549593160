import React, { Component } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Snackbar,
  withMobileDialog, CircularProgress
} from "@material-ui/core";
import SquareAction from "./subcomponent/SquareAction";
import InputMask from "react-input-mask";
import HomeStatus from "./subcomponent/HomeStatus";
import BrotherService from "../../../services/BrotherService";
import DemandService from "../../../services/DemandService";

import { getUser, getToken, setUsername, logout } from "../../../services/auth";

import { CopyToClipboard } from "react-copy-to-clipboard";
// import _ from "lodash";

var ResponsiveDialog = withMobileDialog()(Dialog);

export default class Home extends Component {
  state = {
    showPhoneModal: false,
    user_cim: getUser(),
    token: getToken(),
    phone: undefined,
    errorPhone: false,
    user: null,
    isLoading: true,
    snackbarOpen: false,
    demandCounter: []
  };

  componentDidMount() {
    const { user_cim } = this.state;
    BrotherService.getBrotherByCim(user_cim)
      .then(response => {
        this.setState({ user: response.data.result });

        setUsername(response.data.result.pessoa_nome);
      })
      .catch(console.error);

    BrotherService.getPhone(user_cim)
      .then(res => {
        if (res) {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch(e => {
        this.setState({
          showPhoneModal: true,
          isLoading: false,
        });
      });

    DemandService.getDemandsCounter(user_cim).then(response => {
      this.setState({ demandCounter: response.data.result });
    });
  }

  onInputChange = e => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Phone regex
    const regex = new RegExp(/^\([1-9]{2}\) (?:9[1-9])[0-9]{3}\-[0-9]{4}$/g);
    let regexTest = regex.test(inputValue);

    this.setState({ [inputName]: inputValue, errorPhone: !regexTest });
  };

  updatePhone = () => {
    const { user_cim, phone } = this.state;

    if (!this.state.errorPhone) {
      BrotherService.insertPhone(user_cim, phone)
        .then(res => {
          if (res.data.success) {
            this.setState({ showPhoneModal: false });
          }
        })
        .catch(console.error);
    }
  };

  logout() {
    logout();
    this.props.history.push({
      pathname: "/login"
    });
  }

  render() {
    const { showPhoneModal, isLoading } = this.state;

    if (isLoading) {
      return (
        <Grid container alignItems="stretch" justify="space-evenly">
          <CircularProgress color="secondary" style={{ margin: 30 }} />
        </Grid>
      )
    }

    return (
      <Grid container alignItems="flex-start" style={{ marginBottom: "5vh" }}>
        <Grid item xs={12}>
          <Grid container justify="center" alignItems="stretch">
            <Grid
              item
              xs={11}
              sm={10}
              md={5}
              lg={4}
              style={{ marginTop: "5vh" }}
            >
              <SquareAction
                icon="add_circle_outline"
                title="Nova Demanda"
                link="/novaDemanda"
              />
            </Grid>
            <Grid item xs={false} md={1} style={{ marginTop: "5vh" }} />
            <Grid
              item
              xs={11}
              sm={10}
              md={5}
              lg={4}
              style={{ marginTop: "5vh" }}
            >
              <SquareAction
                icon="view_headline_outlined"
                title="Demandas"
                link="/demandas"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center" alignItems="stretch">
            <Grid
              item
              xs={11}
              sm={10}
              md={5}
              lg={4}
              style={{ marginTop: "5vh" }}
            >
              <SquareAction
                icon="search_outlined"
                title="Buscar Irmão"
                link="buscaIrmao"
              />
            </Grid>
            <Grid item xs={false} md={1} style={{ marginTop: "5vh" }} />
            <Grid
              item
              xs={11}
              sm={10}
              md={5}
              lg={4}
              style={{ marginTop: "5vh" }}
            >
              <CopyToClipboard
                text={"gestaodegoverno@gosc.org.br"}
                onCopy={() => this.setState({ snackbarOpen: true })}
              >
                <a>
                  <SquareAction
                    icon={"mail_outlined"}
                    title={"Hospitalaria GOSC"}
                    noLink
                  />
                </a>
              </CopyToClipboard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "5vh" }}>
          <Grid container justify="center">
            <Grid item xs={11} sm={10} md={9} lg={9}>
              <HomeStatus {...this.state.demandCounter} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "5vh" }}>
          <Grid container justify="center">
            <Grid item xs={10} sm={9} lg={9} container justify="flex-end">
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => this.logout()}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* Phone popup */}
        <ResponsiveDialog
          aria-labelledby="form-dialog-title"
          aria-describedby="simple-modal-description"
          open={showPhoneModal}
        >
          <DialogTitle
            id="form-dialog-title"
            disableTypography
            style={{ borderTop: "solid 10px #003158", borderRadius: "4px" }}
          >
            <Typography color="secondary" variant="h1">
              Olá {this.state.user && this.state.user.nome}
            </Typography>
            <Typography variant="h3">
              Por favor, finalize seu cadastro:
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" id="simple-modal-description">
              Para podermos enviar seu contato à outros usuários e você se
              comunicar utilizando nossa aplicação, favor adicione seu telefone
              vinculado ao <b>WhatsApp</b>
            </Typography>
            <InputMask
              mask="(99) 99999-9999"
              value={this.state.phone}
              onChange={this.onInputChange}
            >
              {inputProps => (
                <TextField
                  name={"phone"}
                  id="phone"
                  type="phone"
                  label="Telefone do WhatsApp"
                  margin="normal"
                  variant="outlined"
                  {...inputProps}
                  error={this.state.errorPhone}
                  style={{ marginTop: 32 }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              )}
            </InputMask>

            <Button
              variant="contained"
              onClick={this.updatePhone}
              color="primary"
              fullWidth
              size="large"
              style={{ marginTop: 16, padding: 16 }}
            >
              Atualizar Telefone
            </Button>
          </DialogContent>
        </ResponsiveDialog>

        {/* Gosc contact */}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={5000}
          onClose={() => {
            this.setState({ snackbarOpen: false });
          }}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={
            <span id="message-id">
              <Typography color="secondary" component="span">
                gestaodegoverno@gosc.org.br
              </Typography>
              O email de contato do GOSC foi copiado para a Área de
              Transferência.
            </span>
          }
        />
      </Grid>
    );
  }
}
