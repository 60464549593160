import React, { Component } from "react";
import { Typography, Grid, Card, CardActionArea } from "@material-ui/core";
import { Link } from "react-router-dom";

export default class HomeStatus extends Component {
  render() {
    return (
      <Grid container direction="row" justify="center" alignItems="stretch">
        <Grid item xs={4} component={Card}>
          <Link
            to={{
              pathname: "/demandas",
              state: {
                tabFilter: "publics"
              }
            }}
            style={{ textDecoration: "none" }}
          >
            <CardActionArea style={{ paddingTop: 16, paddingBottom: 16 }}>
              <Typography
                align="center"
                variant="h1"
                color="primary"
                style={{ fontSize: "3em", lineHeight: "1", fontWeight: 600 }}
              >
                {this.props.allPublic ? this.props.allPublic.count : "--"}
              </Typography>
              <Typography
                variant="body2"
                color="secondary"
                align="center"
                style={{ paddingLeft: 20, paddingRight: 20 }}
              >
                Demandas Públicas
              </Typography>
            </CardActionArea>
          </Link>
        </Grid>
        <Grid item xs={4} component={Card}>
          <Link
            to={{
              pathname: "/demandas",
              state: {
                tabFilter: "received"
              }
            }}
            style={{ textDecoration: "none" }}
          >
            <CardActionArea style={{ paddingTop: 16, paddingBottom: 16 }}>
              <Typography
                align="center"
                variant="h1"
                color="primary"
                style={{ fontSize: "3em", lineHeight: "1", fontWeight: 600 }}
              >
                {this.props.received ? this.props.received.count : "--"}
              </Typography>
              <Typography
                variant="body2"
                color="secondary"
                align="center"
                style={{ paddingLeft: 20, paddingRight: 20 }}
              >
                Demandas Recebidas
              </Typography>
            </CardActionArea>
          </Link>
        </Grid>
        <Grid item xs={4} component={Card}>
          <Link
            to={{
              pathname: "/demandas",
              state: {
                tabFilter: "mine"
              }
            }}
            style={{ textDecoration: "none" }}
          >
            <CardActionArea style={{ paddingTop: 16, paddingBottom: 16 }}>
              <Typography
                align="center"
                variant="h1"
                color="primary"
                style={{ fontSize: "3em", lineHeight: "1", fontWeight: 600 }}
              >
                {this.props.mine ? this.props.mine.count : "--"}
              </Typography>
              <Typography
                variant="body2"
                color="secondary"
                align="center"
                style={{ paddingLeft: 20, paddingRight: 20 }}
              >
                Demandas Criadas
              </Typography>
            </CardActionArea>
          </Link>
        </Grid>
      </Grid>
    );
  }
}
