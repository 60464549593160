import React, { Component } from "react";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  CircularProgress
} from "@material-ui/core";
import Filter from "@material-ui/icons/FilterListOutlined";

import Pagination from "material-ui-flat-pagination";

import BrotherListItem from "./subcomponent/BrotherListItem";
import BigButton from "../../components/BigButton";

import BrotherService from "../../../services/BrotherService";

export default class BrotherSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Filtros de Busca
      name: "",
      profession: "",
      workplace: "",
      formation: "",
      grade: "",
      oriente: "",
      rito: "",
      lodge: "",
      search: true,

      // Api
      brothers: [],
      filteredBrothers: [],
      loading: true,
      errorService: false,

      // Paginacao
      pageTotalItems: 0,
      pageOffset: 0,
      itemsPerPage: 10
    };
  }

  componentWillMount() {
    this.setState({
      loading: false
    });
  }

  searchBrother = async () => {
    this.setState({ loading: true });
    const {
      name,
      profession,
      formation,
      grade,
      workplace,
      lodge,
      rito,
      oriente
    } = this.state;

    const brothersToSearch = {
      nome: name,
      ocupacao: profession,
      formacao: formation,
      grau: grade,
      onde_exerce: workplace,
      loja_atual: lodge,
      rito,
      oriente
    };

    try {
      let filteredBrothers = await BrotherService.searchBrothers(
        brothersToSearch
      );
      this.setState({
        filteredBrothers: filteredBrothers.data.result,
        pageOffset: 0,
        pageTotalItems: filteredBrothers.data.result.length,
        search: false,
        loading: false
      });
    } catch (e) {
      this.setState({ errorService: true, loading: false });
    }
  };

  toggleSearchArea = () => {
    this.setState(prevState => ({
      search: !prevState.search
    }));
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handlePageClick = data => {
    this.setState({ pageOffset: data.selected });
  };

  render() {
    let lowerCut = this.state.pageOffset;
    let upperCut = this.state.pageOffset + this.state.itemsPerPage;
    let brothersSliced = this.state.filteredBrothers.slice(lowerCut, upperCut);

    brothersSliced = brothersSliced.map((brother, index) => (
      <Grid
        item
        xs={11}
        sm={10}
        md={5}
        lg={5}
        style={{ marginTop: "5vh" }}
        key={index}
      >
        <BrotherListItem {...brother} key={index} />
      </Grid>
    ));

    return (
      <Grid
        container
        alignItems="stretch"
        justify="center"
        style={{ marginBottom: "5vh" }}
      >
        <Grid
          item
          container
          justify="space-between"
          alignItems="center"
          xs={11}
          style={{ marginTop: "5vh" }}
        >
          <Typography variant="h1">Buscar Irmão</Typography>
          <Button
            variant="contained"
            color="secondary"
            aria-label="Buscar Irmão"
            label="Buscar"
            onClick={this.toggleSearchArea}
          >
            <Filter className="white-info" />
            <Typography variant="body1" className="white-info">
              Filtros
            </Typography>
          </Button>
          {/* </Grid> */}
        </Grid>
        {this.state.search && !this.state.loading && (
          <Grid item xs={11} style={{ marginTop: "5vh" }}>
            <Paper style={{ padding: 32 }}>
              <Grid container justify="space-around" spacing={16}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="name"
                    name="name"
                    label="Nome do Irmão"
                    value={this.state.name}
                    variant="filled"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="grade"
                    name="grade"
                    label="Grau"
                    value={this.state.grade}
                    variant="filled"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="lodge"
                    name="lodge"
                    label="Loja"
                    value={this.state.lodge}
                    variant="filled"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="profession"
                    name="profession"
                    label="Profissão"
                    value={this.state.profession}
                    variant="filled"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="formation"
                    name="formation"
                    label="Formação"
                    value={this.state.formation}
                    variant="filled"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="workplace"
                    name="workplace"
                    label="Empresa / Local de Trabalho"
                    value={this.state.workplace}
                    variant="filled"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="oriente"
                    name="oriente"
                    label="Oriente"
                    value={this.state.oriente}
                    variant="filled"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    id="rito"
                    name="rito"
                    label="Rito"
                    value={this.state.rito}
                    variant="filled"
                    fullWidth
                    onChange={this.handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <BigButton
                icon="search-outlined"
                title="Buscar"
                action={this.searchBrother}
              />
            </Paper>
          </Grid>
        )}

        <Grid container alignItems="stretch" justify="space-evenly">
          {this.state.loading && (
            <CircularProgress color="secondary" style={{ margin: 30 }} />
          )}
          {!this.state.loading && brothersSliced}
          {!this.state.loading && brothersSliced.length === 0 && (
            <Typography
              variant="h6"
              justify="center"
              style={{ margin: 32, color: "rgba(0, 0, 0, 0.6)" }}
            >
              Não foram encontrados irmãos com estes filtros
            </Typography>
          )}
        </Grid>
        <Grid container alignItems="flex-start" justify="center">
          {!this.state.loading && (
            <Grid item xs={12} sm={6} md={4} container justify="center">
              <Pagination
                limit={this.state.itemsPerPage}
                offset={this.state.pageOffset}
                total={this.state.pageTotalItems}
                onClick={(e, offset) => this.setState({ pageOffset: offset })}
                style={{ marginTop: "5vh" }}
                size="large"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}
