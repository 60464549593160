import React, { Component } from "react";
import {
  Grid,
  Icon,
  Typography,
  Card,
  CardActionArea,
  Link
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

export default class SquareAction extends Component {
  render() {
    let content = (
      <Card style={{ backgroundColor: "#003158", height: "100%" }}>
        <CardActionArea>
          <Grid
            container
            // direction='column'
            justify="space-between"
            alignItems="center"
            style={{ padding: 32, height: "100%" }}
          >
            <Icon className="white-info" style={{ fontSize: "5em" }}>
              {this.props.icon}
            </Icon>
            <Typography align="center" className="white-info" variant="h2">
              {this.props.title}
            </Typography>
          </Grid>
        </CardActionArea>
      </Card>
    );

    if(this.props.noLink)
      return content;

    return (
      <Link component={RouterLink} to={this.props.link || ""}>
        {content}
      </Link>
    );
  }
}
